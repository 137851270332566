@import './styles/fullcalendar.css';
@import './styles/MapaPedido.css';

:root {
  --focus-border: var(--primary-main) !important
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 3px;
  position: fixed;

}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

scrollbar {
  width: 10px;
}

scrollbar-track {
  background: #f1f1f1;
}

scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

scrollbar-thumb:hover {
  background: #555;
}

.sash {
  background-color: transparent;
}

.sash-active,
.sash-hover {
  background-color: var(--primary-main);
}