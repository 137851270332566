.slide-enter {
    transform: translateX(-100%);
    opacity: 0;
}

.slide-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform .3s, opacity .3s;
}

.slide-exit {
    transform: translateX(0);
    opacity: 1;
}

.slide-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform .3s, opacity .3s;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity .3s;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity .3s;
}
