.cls-1 {
  fill: #fea110;
}

.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7,
.cls-8 {
  stroke-width: 0px;
}

.cls-2 {
  fill: #aae849;
}

.ocupado {
  fill: #ff0000
}

.cls-3 {
  fill: #463c4b;
}

.cls-4 {
  fill: #555a6e;
}

.cls-5 {
  fill: #73788b;
}

.cls-6 {
  fill: #fefefe;
}

.cls-7 {
  fill: #e89a20;
}

.cls-8 {
  fill: #faa218;
}

.popupContent {
  color: black;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.tituloDiv {
  font-size: 16px;
}

.dataDiv,
.tipoVeiculoDiv,
.ultimoPedidoDiv,
.contatoDiv {
  font-size: 12px;
}

.bateriaDiv {
  font-size: 12px;
}

.bateriaAlta {
  color: green;
}

.bateriaMedia {
  color: rgb(206, 206, 0);
}

.bateriaBaixa {
  color: red;
}

.contatoDiv {
  margin-bottom: 10px;
}

.buttonsDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

:root {
  --primary-color: #000000;
  --secondary-color: #ffffff;
}

.button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  font-size: 12px;
}


.button3 {
  background-color: #4c9e00;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.whatsappIcon {
  width: 16px;
  height: 16px;
}

.pedidoElement {
  width: 40px;
  height: 25px;
  color: white;
  border: 2px solid white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.codigoDiv {
  margin-top: 14px;
  font-size: 10px;
  font-weight: bold;
  margin-bottom: 10px;
}

.setaDiv {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-top: -10px;
}

.popupContent {
  color: black;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center;
  overflow: hidden;
}

.tituloDiv {
  font-size: 16px;
  margin-bottom: 10px;
}

.nomeDiv {
  display: flex;
  align-items: center;
  gap: 0px 5px;
  margin-bottom: 0px;
  font-size: 14px;
}

.dataCriacaoDiv,
.enderecoDiv,
.pagamentoDiv {
  font-size: 14px;
  margin-bottom: 4px;
}

.botoesDiv {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  height: auto;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 10px;
}

.botao {
  background-color: #FF9F0D;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 15px;
  cursor: pointer;
  flex: 1;
}

.empresaElement {
  width: 12px;
  height: 12px;
  position: relative;
  color: white;
  border: 2px solid white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.iconBuildingDiv {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 25px;
}