:root {
    /* --fc-list-event-dot-width: 10px; */
    --fc-list-event-hover-bg-color: var(--primary-dark);
    --fc-small-font-size: .85em;
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: var(--background-paper);
    --fc-neutral-text-color: grey;
    --fc-border-color: #ddd;
    --fc-button-text-color: #fff;
    --fc-button-bg-color: #2c3e50;
    --fc-button-border-color: #2c3e50;
    --fc-button-hover-bg-color: #1e2b37;
    --fc-button-hover-border-color: #1a252f;
    --fc-button-active-bg-color: #1a252f;
    --fc-button-active-border-color: var(--primary-dark);
    --fc-event-bg-color: #3788d8;
    --fc-event-border-color: #3788d8;
    --fc-event-text-color: #fff;
    --fc-event-selected-overlay-color: rgba(0, 0, 0, .25);
    --fc-more-link-bg-color: #d0d0d0;
    --fc-more-link-text-color: inherit;
    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px;
    --fc-non-business-color: hsla(0, 0%, 84%, .3);
    --fc-bg-event-color: #8fdf82;
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: rgba(188, 232, 241, .3);
    --fc-today-bg-color: rgba(255, 220, 40, .15);
    --fc-now-indicator-color: red;
}

.fc-event-title {
    font-weight: bold;
    font-size: 14px;
}

.tooltip {
    position: absolute;
    top: 50%;
    left: -100%;
    transform: translate(-90%, -50%);
    background-color: #505050;
    color: #fff;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1000;
    transition: opacity 0.2s ease-in-out;
}

.tooltip::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent transparent #505050;
}

@media screen and (min-width: 960px) {
    /* has-button -> eventos de dia único com botão */
    /* dia-diferente -> eventos de dia diferente */
    /* eh-sabado -> eventos que começam no sábado */

    .fc-event {
        height: 21px;
        margin-bottom: 2px;
        font-weight: 900;
    }

    .fc-daygrid-event-dot {
        display: none;
    }

    .fc-daygrid-event-harness.has-button {
        display: flex;
        justify-content: space-between;
        gap: 2px;
        box-sizing: border-box;
        width: 100%;
        padding: 1px 0 2px 0;
    }

    .fc-daygrid-event-harness.has-button .fc-event {
        flex: 1 1 100%;
        margin: 0px;
        overflow: hidden;
        z-index: 0;
    }

    .fc-daygrid-event-harness.has-button .fc-event-title {
        text-wrap: nowrap;
        word-break: break-all;
        text-overflow: ellipsis;
    }

    .fc-daygrid-event-harness.has-button button,
    .fc-daygrid-event-harness.dia-diferente button {
        height: 21px;
        width: 21px;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-items: center;
        border: none;
        cursor: pointer;
        border-radius: 4px;
        position: relative;
        z-index: 1;
    }

    /* Estilizar eventos de mais de um dia */
    .fc-daygrid-event-harness.dia-diferente {
        box-sizing: border-box;
        display: flex;
        box-sizing: border-box;
        padding: 1px 0 2px 0;
        z-index: 1;
    }

    .fc-daygrid-event-harness.dia-diferente>a {
        display: flex;
        max-width: 100%;
        height: 21px;
        padding: 0 0 0 5px;
        border: none;
        align-items: center;
        flex: 1 1 100%;
        margin: 0px;
        z-index: 0;
    }

    .fc-daygrid-event-harness.dia-diferente .fc-event-main {
        overflow: hidden;
        flex: 1 1 100%;
        height: min-content;
        color: inherit;
        z-index: 0;
    }

    .fc-daygrid-event-harness.dia-diferente .fc-event-time {
        min-width: fit-content;
    }

    .fc-daygrid-event-harness.dia-diferente .fc-event-title {
        padding: 0;
        color: inherit;
        line-height: 16px;
        text-wrap: nowrap;
        word-break: break-all;
        text-overflow: ellipsis;
    }

    .fc-daygrid-event-harness.dia-diferente .fc-event-time {
        line-height: 16px;
        padding: 0;
    }

    .fc-h-event .fc-event-main {
        color: inherit !important;
    }

    /* Lidando com quebra que ocorre no sábado p/ domingo */
    .fc-daygrid-event-harness.dia-diferente.eh-sabado button {
        display: none
    }

    /* Apenas para o domingo */
    tr td .fc-day:last-child .fc-daygrid-event-harness.dia-diferente.eh-sabado button {
        display: flex
    }

    tr td .fc-day .fc-daygrid-event-harness.dia-diferente.eh-sabado {
        width: 100%;
        flex: 1 1 100%;
    }

    tr td .fc-day .fc-daygrid-event-harness.dia-diferente.eh-sabado {
        width: 100%;
        flex: 1 1 100%;
        padding-right: 2px;
    }

    tr td .fc-day .fc-daygrid-event-harness.dia-diferente.eh-sabado .fc-event {
        display: flex;
        gap: 2px;
    }

    tr td .fc-day .fc-daygrid-event-harness.dia-diferente.eh-sabado .fc-event .fc-event-main {
        overflow: hidden;
        display: flex;
        flex: 0 1 100%;
    }

    tr td .fc-day .fc-daygrid-event-harness.dia-diferente.eh-sabado .fc-event .fc-event-main-frame {
        overflow: hidden;
        display: flex;
        gap: 0;
        flex: 0 1 100%;
    }

    tr td .fc-day .fc-daygrid-event-harness.dia-diferente.eh-sabado .fc-event-title {
        flex: 1 1 100%;
        text-wrap: nowrap;
        word-break: break-all;
        color: inherit;
        text-overflow: ellipsis;
    }
}

@media screen and (max-width: 959px) {
    .fc-list-event+button {
        display: none;
    }

    .fc-list-event-graphic {
        display: none;
    }
}

/* Estilo para os eventos no calendário */
.fc-daygrid-event {
    border-radius: 5px;
    padding: 2px 5px;
}

/* Estilo para o título dos eventos */
.fc-event-title {
    font-weight: bold;
    font-size: 14px;
    text-wrap: nowrap;
    word-break: break-all;
    text-overflow: ellipsis;
}

/* Estilo para a barra de ferramentas (header) do calendário */
.fc-toolbar.fc-header-toolbar {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

/* Estilo para os botões de navegação e título */
.fc-toolbar-title {
    color: var(--primary-main);
    font-size: 18px;
    font-weight: bold;
}

.fc .fc-button-primary:not(:disabled):active {
    outline: var(--primary-light) solid 1px;
    background-color: var(--primary-dark);
}

.fc-button.fc-button-primary,
.fc-button.fc-button-primary.fc-today-button {
    background-color: var(--primary-main);
    color: var(--primary-contrastText);
    border-color: var(--primary-dark);
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

/* Hover e active para os botões */
.fc-button.fc-button-primary:hover,
.fc-button.fc-button-primary:active {
    background-color: var(--primary-dark);
    color: var(--primary-contrastText);
}

.fc-col-header-cell-cushion {
    user-select: none;
}

/* Estilizar a borda dos dias do calendário */
.fc-daygrid-day {
    border: 1px solid #e0e0e0;
}

.fc-daygrid-day-number {
    user-select: none;
}

.custom-event-pointer {
    cursor: pointer;
}

.fc-list-table,
.fc .fc-list-sticky .fc-list-day>* {
    background: #ffffff00;
}

.fc-media-screen {
    height: 100%;
}

.fc-listMonth-view {
    border-radius: 5px;
}

@media screen and (max-width: 600px) {
    .fc-header-toolbar .fc-toolbar-chunk:last-of-type {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    .fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
        margin-left: 0;
    }
}

.fc-event-dragging {
    transform: translate(-50px, -60px);
}