.custom-marker {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

/* Estilos específicos para o marcador da empresa */
.empresa-marker {
  background-color: var(--primary-main);
  width: 26px;
  height: 26px;
  position: relative;
}

.empresa-marker::after {
  content: '';
  bottom: -50%;
  background-color: inherit;
  width: 50%;
  height: 50%;
  transform: translateY(-70%) rotate(45deg);
  position: absolute;
  z-index: -1;
}

.empresa-marker::before {
  content: '';
  background-color: #fff;
  border-radius: 50%;
  width: 50%;
  height: 50%;
  position: absolute;
  z-index: 1;
}

/* Estilos específicos para o marcador do pedido */
.pedido-marker {
  background-color: var(--secondary-main);
  width: 26px;
  height: 26px;
  position: relative;
}

.pedido-marker::after {
  content: '';
  bottom: -50%;
  background-color: inherit;
  width: 50%;
  height: 50%;
  transform: translateY(-70%) rotate(45deg);
  position: absolute;
  z-index: -1;
}

.pedido-marker::before {
  content: '';
  background-color: #fff;
  border-radius: 50%;
  width: 50%;
  height: 50%;
  position: absolute;
  z-index: 1;
}

.markerPointPedido {
  color: #000;
}